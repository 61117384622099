:root {
        --color-white: #fff;
        --color-red: #990524;
        --color-blue: #000e72;
        --color-green: #00543c;
        --color-orange: #ea8c2c;
        --color-yellow: #ffee00;
        --color-gray: #a19e97;
        --color-gray-light: #ccc;
        --color-green-dark: #00543c;
        --color-gray-light: #a19e97;
        --color-gray-lighter: #eee;
        --color-gray-dark: #4a4c4b;
        --color-gray-darker: #313332;

        --color-primary: #990524;
        --color-secondary: var(--color-white);
        --color-group-promo: var(--color-orange);

        --font-size-base: calc(10px + .4vmax);
        --font-size-big: calc(var(--font-size-base) * 2);
        --font-size-medium: calc(var(--font-size-base) * 1.15);
        --font-size-small: calc(var(--font-size-base) *.9);
        --font-size-label: calc(var(--font-size-base) * .9);
        --font-size-promo: var(--font-size-base);
}