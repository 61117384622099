/* lato-regular - latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url('https://cdn.hetwijnhuis.be/lib/fonts/lato-v14-latin/lato-v14-latin-regular.eot');
    /* IE9 Compat Modes */
    src: local('Lato Regular'), local('Lato-Regular'),
        url('https://cdn.hetwijnhuis.be/lib/fonts/lato-v14-latin/lato-v14-latin-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('https://cdn.hetwijnhuis.be/lib/fonts/lato-v14-latin/lato-v14-latin-regular.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('https://cdn.hetwijnhuis.be/lib/fonts/lato-v14-latin/lato-v14-latin-regular.woff') format('woff'),
        /* Modern Browsers */
        url('https://cdn.hetwijnhuis.be/lib/fonts/lato-v14-latin/lato-v14-latin-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('https://cdn.hetwijnhuis.be/lib/fonts/lato-v14-latin/lato-v14-latin-regular.svg#Lato') format('svg');
    /* Legacy iOS */
}

/* lato-italic - latin */
@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 400;
    src: url('https://cdn.hetwijnhuis.be/lib/fonts/lato-v14-latin/lato-v14-latin-italic.eot');
    /* IE9 Compat Modes */
    src: local('Lato Italic'), local('Lato-Italic'),
        url('https://cdn.hetwijnhuis.be/lib/fonts/lato-v14-latin/lato-v14-latin-italic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('https://cdn.hetwijnhuis.be/lib/fonts/lato-v14-latin/lato-v14-latin-italic.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('https://cdn.hetwijnhuis.be/lib/fonts/lato-v14-latin/lato-v14-latin-italic.woff') format('woff'),
        /* Modern Browsers */
        url('https://cdn.hetwijnhuis.be/lib/fonts/lato-v14-latin/lato-v14-latin-italic.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('https://cdn.hetwijnhuis.be/lib/fonts/lato-v14-latin/lato-v14-latin-italic.svg#Lato') format('svg');
    /* Legacy iOS */
}

/* lato-700 - latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: url('https://cdn.hetwijnhuis.be/lib/fonts/lato-v14-latin/lato-v14-latin-700.eot');
    /* IE9 Compat Modes */
    src: local('Lato Bold'), local('Lato-Bold'),
        url('https://cdn.hetwijnhuis.be/lib/fonts/lato-v14-latin/lato-v14-latin-700.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('https://cdn.hetwijnhuis.be/lib/fonts/lato-v14-latin/lato-v14-latin-700.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('https://cdn.hetwijnhuis.be/lib/fonts/lato-v14-latin/lato-v14-latin-700.woff') format('woff'),
        /* Modern Browsers */
        url('https://cdn.hetwijnhuis.be/lib/fonts/lato-v14-latin/lato-v14-latin-700.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('https://cdn.hetwijnhuis.be/lib/fonts/lato-v14-latin/lato-v14-latin-700.svg#Lato') format('svg');
    /* Legacy iOS */
}

/* lato-700italic - latin */
@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 700;
    src: url('https://cdn.hetwijnhuis.be/lib/fonts/lato-v14-latin/lato-v14-latin-700italic.eot');
    /* IE9 Compat Modes */
    src: local('Lato Bold Italic'), local('Lato-BoldItalic'),
        url('https://cdn.hetwijnhuis.be/lib/fonts/lato-v14-latin/lato-v14-latin-700italic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('https://cdn.hetwijnhuis.be/lib/fonts/lato-v14-latin/lato-v14-latin-700italic.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('https://cdn.hetwijnhuis.be/lib/fonts/lato-v14-latin/lato-v14-latin-700italic.woff') format('woff'),
        /* Modern Browsers */
        url('https://cdn.hetwijnhuis.be/lib/fonts/lato-v14-latin/lato-v14-latin-700italic.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('https://cdn.hetwijnhuis.be/lib/fonts/lato-v14-latin/lato-v14-latin-700italic.svg#Lato') format('svg');
    /* Legacy iOS */
}

/* lato-900 - latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    src: url('https://cdn.hetwijnhuis.be/lib/fonts/lato-v14-latin/lato-v14-latin-900.eot');
    /* IE9 Compat Modes */
    src: local('Lato Black'), local('Lato-Black'),
        url('https://cdn.hetwijnhuis.be/lib/fonts/lato-v14-latin/lato-v14-latin-900.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('https://cdn.hetwijnhuis.be/lib/fonts/lato-v14-latin/lato-v14-latin-900.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('https://cdn.hetwijnhuis.be/lib/fonts/lato-v14-latin/lato-v14-latin-900.woff') format('woff'),
        /* Modern Browsers */
        url('https://cdn.hetwijnhuis.be/lib/fonts/lato-v14-latin/lato-v14-latin-900.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('https://cdn.hetwijnhuis.be/lib/fonts/lato-v14-latin/lato-v14-latin-900.svg#Lato') format('svg');
    /* Legacy iOS */
}

/* lato-900italic - latin */
@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 900;
    src: url('https://cdn.hetwijnhuis.be/lib/fonts/lato-v14-latin/lato-v14-latin-900italic.eot');
    /* IE9 Compat Modes */
    src: local('Lato Black Italic'), local('Lato-BlackItalic'),
        url('https://cdn.hetwijnhuis.be/lib/fonts/lato-v14-latin/lato-v14-latin-900italic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('https://cdn.hetwijnhuis.be/lib/fonts/lato-v14-latin/lato-v14-latin-900italic.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('https://cdn.hetwijnhuis.be/lib/fonts/lato-v14-latin/lato-v14-latin-900italic.woff') format('woff'),
        /* Modern Browsers */
        url('https://cdn.hetwijnhuis.be/lib/fonts/lato-v14-latin/lato-v14-latin-900italic.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('https://cdn.hetwijnhuis.be/lib/fonts/lato-v14-latin/lato-v14-latin-900italic.svg#Lato') format('svg');
    /* Legacy iOS */
}