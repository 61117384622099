@import "css-variables";
@import "holy-grail";
@import "fonts";
@import "header";

// undo padding top fom main.scss (buggy here)
body {
    &.sticky-header-off {
        #wrapper {

            #divHeader+* {
                padding-top: initial;
            }
        }
    }
}