.header {
	--header-main-bgcolor: var(--color-gray-lighter);
	--header-main-color: var(--color-gray-dark);
	--header-serv-bgcolor: var(--color-gray-light);
	--header-serv-color: var(--color-white);
	--header-search-bgcolor: var(--color-gray-lighter);
	--header-search-color: var(--color-gray-dark);

	--header-font-size: var(--font-size-small);
	--header-line-height: var(--header-font-size);
	--header-padding-vertical: calc(var(--header-font-size) * .5);
	--header-padding-horizontal: 0;

	--header-border-radius: calc(var(--header-font-size) * 2);
	--header-empty-space-vertical: calc(var(--header-font-size) / 2.2);
	--header-empty-space-horizontal: calc(var(--header-empty-space-vertical) * 1.63);

	--header-height-calculated: 220px;

	--filters-font-size: calc(4px + .2vmax);
	--filters-line-height-calculated: 33px;
	--filters-bgcolor: var(--color-white);
	--filters-color: var(--color-gray-dark);
	--filters-border-color: var(--color-red);
	--filters-bgcolor__hover: var(--color-gray-lighter);
	--filters-color__hover: var(--color-gray-dark);
	--filters-border-color__hover: var(--filters-color);
	--filters-empty-space-vertical: calc(var(--filters-font-size) / 3);
	--filters-empty-space-horizontal: var(--filters-empty-space-vertical);

	// display: flex;
	// flex-direction: column;
	font-size: var(--header-font-size);

	.sticky {
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 900;
	}

	.sticky+* {
		padding-top: var(--header-height-calculated);
	}

	.sticky-logo {
		display: block;
		max-height: var(--header-sticky-logo-height);
		margin: var(--header-padding-vertical) auto;
		visibility: hidden;
		transition: all 0.2s ease-out;

		&.make-visible {
			visibility: visible;
		}
	}

	.flex-container {
		display: flex;

		&.wrap {
			flex-wrap: wrap;
		}

		&.middle {
			align-items: center;
			height: 100%;
		}
	}

	&--main,
	&--service-links,
	&--main-navigation {
		.flex-container {
			flex-direction: row;
		}
	}

	&--main {
		justify-content: space-between;
		order: 2;
		background-color: var(--header-main-bgcolor);
		color: var(--header-main-color);

		.main {

			&-left,
			&-middle,
			&-right {
				flex-basis: 33.33%;
			}

			&--logo {
				width: 80%;
				margin: var(--header-padding-vertical) 10%;

			}
		}
	}

	&--service-links {
		order: 1;
		background-color: var(--header-serv-bgcolor);
		color: var(--header-serv-color);
		border-bottom: 1px solid var(--color-gray-light);
		line-height: var(--header-line-height);
		text-align: center;

		.service {

			&-left,
			&-middle,
			&-right {
				flex-basis: 33.33%;
			}
		}
	}

	&--mixed-search {
		order: 4;
		background-color: var(--header-search-bgcolor);
		color: var(--header-search-color);
		box-shadow: $box-shadow;
	}

	&--main-navigation {
		order: 3;
		background-color: var(--color-gray-lighter);
		color: var(--color-gray-dark);
	}

	.main-menu {
		font-size: var(--font-size-medium);

		.menu-button {
			display: inline-block;
			margin: 0;
			padding: 0;
			border: none;
			background-color: inherit;
			color: inherit;
			cursor: pointer;

			.button-inner {
				padding: calc(var(--filters-empty-space-vertical) * 2) calc(var(--filters-empty-space-horizontal) * 2);

				&:hover {
					color: var(--color-red);
				}
			}

			.icon {
				font-size: $desktopFontSizeBig;
			}
		}
	}

	.filters-list {
		display: inline-block;

		.dropdown-toggle {
			margin: 0;
			padding: calc(var(--filters-empty-space-vertical) * 3) calc(var(--filters-empty-space-horizontal) * 3);
			border: none;
			color: var(--color-gray-dark);
			background-color: transparent;
			cursor: pointer;
			min-width: calc(var(--font-size-base) * 5);

			&:hover,
			&:focus {
				color: var(--color-red);
				font-weight: bold;
			}

			.group-desc {
				font-weight: bold;
				text-transform: uppercase;
				border-bottom: 1px dotted var(--color-gray-dark);
			}

			&:focus {
				.group-desc {
					border-bottom: 3px solid var(--color-red);
				}
			}
		}

		.dropdown-menu {
			margin: 0;
			padding: 0;
			min-width: 13em;
			background-color: var(--color-gray-lighter);
			border-color: var(--color-red);
			border-radius: 0;
			border-top-width: 3px;
			font-size: var(--header-font-size);

			.dropdown-header {
				padding: calc(var(--filters-empty-space-vertical) * 6) calc(var(--filters-empty-space-horizontal) * 4) calc(var(--filters-empty-space-vertical) * 2);
				font-style: italic;
				color: var(--color-gray-light);
				border-bottom: 1px solid var(--color-gray-light);
			}
		}
	}

	.filter-select {
		&.selectable {
			cursor: pointer;
			color: var(--color-gray-dark);

			.subgroup-desc {
				&:hover {
					background-color: var(--color-red);
					color: var(--color-white);
				}
			}
		}

		&.selected {

			.subgroup-desc {
				color: var(--color-red);
				font-weight: bold;

				&::after {
					content: " ✓";
				}
			}
		}

		.subgroup-desc {
			display: block;
			padding: calc(var(--filters-empty-space-vertical) * 2) calc(var(--filters-empty-space-horizontal) * 4);
		}
	}

	// .catalogue-search__menu,
	// .filters-display__panel {
	// 	flex-basis: 50%;
	// 	padding: 0 var(--header-empty-space-horizontal);
	// }

	.catalogue-search__menu {
		text-align: right;
	}

	.filters-display__panel {
		text-align: left;

		.group-desc {
			padding-left: calc(var(--filters-empty-space-horizontal) * 2);
			font-size: smaller;
			font-style: italic;

			&::after {
				content: ":";
			}
		}

		.subgroup-desc {
			padding-left: calc(var(--filters-empty-space-horizontal));
			padding-right: calc(var(--filters-empty-space-horizontal));
		}

		.help-text__wrapper,
		.filter-display__wrapper,
		.filters-label {
			display: inline-block;
		}

		.help-text {
			color: var(--color-gray-light);
			padding-left: var(--filters-empty-space-horizontal);
		}

		.help-text,
		.filter-display {
			display: block;
			line-height: var(--header-font-size);
			margin: calc(var(--filters-empty-space-vertical) / 2) calc(var(--filters-empty-space-horizontal) / 2);
		}

		.filter-display {
			padding: var(--filters-empty-space-vertical) var(--filters-empty-space-horizontal);
			background-color: var(--filters-bgcolor);
			color: var(--filters-color);
			border-radius: var(--header-border-radius);
			border: 1px solid var(--filters-border-color);

			button {
				&.remove-item {
					border-radius: 50%;
					background-color: var(--color-gray-light);
					color: var(--color-gray-lighter);
					border: 1px solid transparent;
				}
			}

			&:hover {
				color: var(--filters-color__hover);
				background-color: var(--filters-bgcolor__hover);
				border-color: var(--filters-border-color__hover);

				button {
					&.remove-item {
						background-color: var(--color-red);
					}
				}
			}
		}
	}

	.keywords-container {
		flex-basis: 100%;
		margin: var(--header-empty-space-vertical) var(--header-empty-space-horizontal);

		// .keywords {
		// 	&-left {
		// 		order: 1;
		// 		flex-basis: 35%;
		// 	}

		// 	&-center {
		// 		order: 2;
		// 		flex-basis: 35%;
		// 	}

		// 	&-right {
		// 		order: 3;
		// 		flex-basis: 30%;

		// 		.main-menu {
		// 			display: flex;
		// 			justify-content: space-around;
		// 		}
		// 	}

		// 	&-bottom {
		// 		order: 4;
		// 		flex-basis: 100%;
		// 		margin-top: var(--header-empty-space-vertical);
		// 	}
		// }

		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-template-areas: "logo search menu"
			"logo filters filters";
		justify-items: stretch;
		align-items: center;

		.keywords {
			&-left {
				grid-area: logo;

				@include phone {
					margin-bottom: var(--header-empty-space-vertical);
				}
			}

			&-center {
				grid-area: search;
			}

			&-right {
				grid-area: menu;
				display: flex;
				justify-content: flex-end;

				@include phone {
					margin-bottom: var(--header-empty-space-vertical);
				}
			}

			&-bottom {
				grid-area: filters;

				display: grid;
				grid-template-columns: repeat(2, 1fr);
				grid-template-areas: "filters-menu filters-display";

				.catalogue-search__menu {
					grid-area: filters-menu;
				}

				.filters-display__panel {
					grid-area: filters-display;
				}

				@include phone-and-tablet-vertical {
					grid-template-columns: repeat(1, 1fr);
					grid-template-areas:
						"filters-menu"
						"filters-display";

					.catalogue-search__menu {
						margin-top: var(--header-empty-space-vertical);
					}
				}
			}
		}
	}

	.input-container {
		display: flex;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: stretch;
		font-size: $phoneFontNoZoom;
	}

	.input-wrapper {
		flex-grow: 80;
	}

	.submit-wrapper {
		flex-grow: 20;
	}

	.keywords-input-field,
	.keywords-button {
		display: inline-block;
		width: 100%;
		padding: var(--header-empty-space-vertical) var(--header-empty-space-horizontal);
		// font-size: var(--header-font-size);
		border: 1px solid var(--color-gray-light);
		border-radius: var(--header-border-radius);
	}

	.keywords-input-field {
		background-color: var(--color-white);
		border-right-style: none;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	.keywords-button {
		background-color: var(--color-gray-lighter);

		&.search-submit {
			background-color: var(--color-gray-light);
			border-left-style: none;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			transition: .5s;

			&.disabled {
				background-color: var(--color-gray-lighter);
				color: var(--color-gray-dark);
				cursor: help;
			}

			&.enabled {
				background-color: var(--color-red);
				color: var(--color-white);
				animation: submit-pulse 3.5s infinite;
			}
		}
	}

	@keyframes submit-pulse {
		0% {
			box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.35);
		}

		100% {
			box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
		}
	}

	@include phone {

		.keywords-container {

			grid-template-columns: 50% 50%;
			grid-template-areas: "logo menu"
				"search search"
				"filters filters";

			.keywords-input-field {
				width: 100%;
			}

			.brandgroups-dropdown {
				display: none !important;
			}

			// .keywords {
			// 	&-left {
			// 		order: 1;
			// 	}

			// 	&-center {
			// 		order: 3;
			// 	}

			// 	&-right {
			// 		order: 2;
			// 	}

			// 	&-bottom {
			// 		order: 4;
			// 	}

			// 	&-center,
			// 	&-bottom {
			// 		flex-basis: 100%;
			// 	}

			// 	&-left {
			// 		flex-basis: 50%;
			// 	}

			// 	&-right {
			// 		flex-basis: 50%;
			// 		text-align: right;
			// 	}
			// }
		}

		.catalogue-search__menu,
		.filters-display__panel {
			flex-basis: 100%;
			text-align: center;
		}

		.filters-display__panel {
			.help-text__wrapper {
				display: none;
			}
		}

		.sticky-logo {
			visibility: visible;
			max-height: $phoneFontSizeBig;
		}

		.main-menu {
			@include phone-vertical {
				.menu-button {
					.name {
						display: none;
					}
				}
			}

			.badge {
				font-size: var(--header-font-size);
			}
		}
	}
}

/* The Overlay (background) */
.overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #000000aa;
	z-index: 950;
	cursor: pointer;
}

.sidebar {
	--sidebar-width: 300px;
	--sidebar-padding-vertical: $voidDefault;
	--sidebar-background-color: var(--color-gray-lighter);
	--sidebar-foreground-color: var(--color-gray-dark);
	--sidebar-link-color: var(--color-red);
	--sidebar-border-size: 4px;
	--sidebar-border-color: var(--color-white);
	--sidebar-header-color: var(--color-gray-light);
	--sidebar-divider-height: var(--sidebar-border-size);
	--sidebar-divider-color: var(--sidebar-border-color);
	--sidebar-font-size: var(--font-size-medium);

	font-size: var(--sidebar-font-size);

	height: 100%;
	width: 0;
	/* 0 width - change this with JavaScript */
	max-width: 75vw;
	/* max width - % of viewport */
	position: fixed;
	z-index: 999;
	top: 0;
	right: 0;
	background-color: var(--sidebar-background-color);
	overflow-x: hidden;
	/* Disable horizontal scroll */
	padding-top: var(--sidebar-padding-vertical);
	/* Place content 60px from the top */
	transition: 0.8s;
	/* 0.5 second transition effect to slide in the sidebar */

	padding-top: $font-size-base*3;

	&.sidebar-visible {
		width: var(--sidebar-width);
		border-left: var(--sidebar-border-size) solid var(--sidebar-border-color);
	}

	/* The sidebar links */
	a {
		text-decoration: none;
		color: var(--sidebar-foreground-color);

		/* When you mouse over the navigation links, change their color */
		:hover {
			color: var(--sidebar-link-color);
			font-weight: bold;
		}
	}

	/* Position and style the close button (top right corner) */
	.sidebar-close-button {
		position: absolute;
		top: $voidDefault;
		right: $voidDefault;
		border: none;
		background-color: inherit;
		cursor: pointer;

		.button-text {
			font-size: var(--font-size-base);
			text-transform: uppercase;

			:hover {
				font-weight: normal;
			}
		}
	}

	.flat-menu {
		margin: 0;
		padding: 0;

		&-item,
		&-header,
		&-divider {
			display: block;
		}

		&-header {
			padding: $voidDefault $voidDefault*2 0 $voidDefault*2;
			font-size: $desktopFontSizeSmall;
			color: var(--sidebar-header-color);

			.text {
				&::after {
					content: ":";
				}
			}

		}

		&-divider {
			height: var(--sidebar-divider-height);
			background-color: var(--sidebar-divider-color);
		}
	}

	.menu-button,
	.user-link,
	.user-name {
		display: block;
		padding: $voidNarrow $voidDefault*2;
		/* disable text scroll when animating sidebar */
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.user-name {
		background-color: var(--sidebar-border-color);
	}

	.sidebar-menu {
		border-bottom: var(--sidebar-border-size) solid var(--sidebar-border-color);
	}
}

@include phone {
	.logged-in {
		.user-name {
			.link-txt {
				display: block;
			}
		}
	}

	.page-content-start {
		&.sticky-follow {
			min-height: 0;

			&.is-sticky {
				min-height: 8em;
			}
		}
	}
}

#divProductCatalogue {
	padding-top: 3em;
}